.custom-modal .ant-modal-content {
    border-radius: 0 !important;
    width:430px;
    max-width: 600px;
}

.custom-border .ant-select-selector {
    border-radius: 0.2em !important;
}

