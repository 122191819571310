.nav-bar{
    padding: 5px 24px 5px 16px;
    height:100px;
    box-shadow: inset 0px -1px 0px #F0F0F0;
    .logo{
      max-height: 54px;
      max-width: 300px;
      object-fit: contain;
      cursor: pointer;
    }
    .company{
      max-height: 54px;
      max-width: 320px;
      object-fit: contain;
    }
    .btn-profile{
      margin-right: 16px;
    }
    .profile-img{
      width: 50px;
      height: 50px;
      margin-right: 8px;
    }
    .icon{
      margin-left: 0px;
    }
  }
  .documenter-menus{
    max-width: 360px;
    min-width: 240px;
    .btn-profile{
      padding: 6px 12px;
      margin-bottom: 4px;
    }
    .usename{
      margin-top: 4px;
    }
    .profile-signout{
      margin-top: 8px;
      .profile {
        color: #072C3C;
        cursor: pointer;
        text-decoration: none;
      }
    
      .logout {
        color: #FF3333;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .profile-img{
      width: 50px;
      height: 50px;
      margin-right: 8px;
    }
    .menu-entry{
      height: 35px;
      padding: 6px 12px;
      text-decoration: none;
      &:nth-child(1){
        margin-bottom: 12px;
      }
      &.link{
        cursor: pointer;
      }
    }
    .mod-logo{
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  }
  .nav-module-switch{
    min-width: 75px;
    height: 100%;
    margin-top: -4px;
    // background-color: rgba(89, 167, 172, 0.33);
    background-color: rgb(239, 239, 239);
    padding-top: 10.4rem;
    .mod{
      padding: 12px 18px;
      &.active{
        background-color: #173436;
      }
      .icon{
        width: 40px;
        height: 40px;
      }
    }
  }
  .menu-overlay{
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none;
    position: absolute;
    overflow: visible;
    filter: drop-shadow(rgba(0, 0, 0, 0.32) 0px 0px 2px);
  }