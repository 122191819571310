.guide-nav-switch {
    min-width: 75px;
    height: 100%;
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    position: fixed;
    top: 100px;
    left: 0;
    width: 12%;
    height: calc(100% - 30px);
    overflow-y: auto;
    background-color: #f8f9fa;
    z-index: 1000;

    .mod {
        padding: 5px;
        display: flex;
        align-items: center;

        .icon {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }

        .label {
            font-size: 18px;
            color: black;
        }

        .icon-guide {
            font-size: 16px;
            color: #0133CC;
        }

        .label-guide {
            font-size: 20px;
            color: #238787;
            font-weight: 500;
        }
    }
}
.intro {
    padding:30px 0 0px 0px;
    font-size: 15px;
}
.expandable-component {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}

.heading {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.icon {
    margin-right: 15px;
    color: #3358c9;
}

.title {
    display: flex;
}

.description {
    padding: 10px 0;
    font-size: 15px;
    word-break: keep-all;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow-x: auto;
    text-align: justify;
}

.w-10 {
    width: 10%;
}

.w-90 {
    width: 90%;
}

.p5 {
    padding-left: 50px;
}

.custom-hr {
    border:0;
    border-top: 0.5px solid #238787;
}
.sidebar {
    height: 75vh;
}
.txt-justify {
    text-align: justify;
}