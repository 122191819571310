.inc-res-modal {
  .ant-modal-content {
    border-radius: 0;
    padding: 16px;
  }

  .ant-modal-title {
    margin-left: 8px;
  }

  .ant-modal-close {
    top: 26px;
    right: 24px;
    width: 64px;
  }

  .btn-menu {
    width: 28px;
    height: 24px;
    border-color: #0133CC;
    margin-left: 0;
    padding-top: 3px !important;

    .icon-dot {
      font-size: 2px;
    }
  }
}

.resp-menu {
  .menu-list {
    padding: 0;
  }

  .container {
    width: 300px;
    min-height: 100px;

    .footer {
      border-top: 1px solid #F0F0F0;
    }
  }

  .user-info {
    padding: 16px;
  }
}

.change-res-modal {
  .desc {
    margin-top: 4px;
    margin-bottom: 16px;
  }
}

.new-inc-modal {
  .form {
    padding-top: 16px;

    .label {
      width: 78px;
    }
  }

  .label-cont {
    min-width: 96px !important;

    .label {
      width: 90%;
      text-align: right;
    }
  }
}

.view-inc {
  .header {
    padding: 16px 24px;

    .btn-back {
      padding-left: 0;
    }

    .fields {
      .form-input {
        width: 186px;
        margin-left: 24px;
        margin-bottom: 0;
      }
    }
  }

  .side-bar {
    &.close {
      width: 50px;
      padding: 0;
      transition-timing-function: ease-out;

      .sidebar-container {
        transition-timing-function: ease-out;
        opacity: 0;
      }
    }

    &.open {
      padding: 20px 16px 0 16px;
      transition-timing-function: ease-in;

      .sidebar-container {
        transition-timing-function: ease-out;
        opacity: 1;
      }
    }

    .sidebar-container {
      transition: opacity 0.25s;
    }

    width: 306px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    transition: width 0.25s,
    padding 0.25s;
    background-color: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    .footer {
      padding-top: 12px;
      box-shadow: 0px 1px 0px 0px #F0F0F0 inset;
    }

    .field {
      margin-bottom: 20px;

      .key-label {
        width: 30%;
        text-align: right;
        margin-right: 12px;
      }

      .btn-add-resp {
        border: 1px solid #0133CC;
        padding: 1px 4px;
        line-height: 12px;
        align-self: flex-end;
        margin-top: 4px;
      }
    }
  }

  .container {
    background-color: #F9F9F9;
    position: relative;
    padding-top: 2px;
  }

  .history-sec {
    width: 380px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    background: #FFF;
    position: relative;
    z-index: 1;

    .header {
      height: 56px;
      padding: 0 24px;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
    }

    .footer {
      padding: 10px 20px 28px 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.06);

      .btn-submit {
        position: absolute;
        bottom: 8px;
        right: 20px;
      }
    }

    .avator {
      width: 36px;
      height: 32px;
      border-radius: 16px;
      background-color: #DDFFFF;
      border: 1px solid #238787;
      margin-right: 12px;
    }

    .comment-card {
      .avator {
        width: 32px;
      }

      .link-evd {
        padding: 0;
        margin-top: 8px;

        span {
          display: block;
          width: 100%;
          word-break: break-all;
        }
      }

      .comment-text {
        margin-bottom: 0;
        margin-top: 4px;
      }

      padding: 24px 20px 0px 20px;
    }
  }

  .toggle-sec {
    position: relative;
    z-index: 1;
    padding: 8px 10px;
    width: calc(100% - 50px);

    .link-evd {
      padding: 0;
    }

    .panel {
      border-radius: 1px;

      .ant-collapse-content {
        padding: 0px;
      }

      .ant-collapse-content-box {
        padding: 16px;
      }
    }

    .collapse {
      margin-bottom: 16px;
      border-color: #D9D9D9;
      border-radius: 1px;
      background-color: #FAFAFA;

      .ant-collapse-item:last-child>.ant-collapse-content {
        border-radius: 0 0 1px 1px;
      }
    }
  }

  .details-form {
    .form-input .label {
      width: 81px;
    }
  }

  .task-view {
    .create-task-btn {
      margin-left: 16px;
    }

    .ant-collapse-content-box {
      max-height: 320px;
    }

    .doc-table {
      border-radius: 2px;
      margin-top: 8px;
      border: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
}

.create-task-form {
  .form-input .label {
    width: 81px;
  }

  .field-title {
    width: 70%;
  }

  .field-priority {
    width: 30%;
  }

  .field-dept {
    width: 25%;
  }

  .field-source {
    width: 45%;
  }

  .field-due {
    width: 30%;
  }
}

.export-form {
  .form-input .label {
    width: 64px;
  }
}

.inc-stats {
  padding-bottom: 10px;

  .btn-export {
    margin-bottom: 8px;

    .icon {
      margin: 0 0 0 8px;
    }
  }

  .form-input {
    width: 175px;
    margin: 0;
  }

  .title {
    min-width: 190px;
  }

  .stats-tbl {
    table-layout: fixed;
    border-spacing: 0;
    margin-top: 2px;

    tr .cell {
      padding: 8px;
      border-top: 1px solid #238787;
      border-left: 1px solid #238787;

      &:last-child {
        border-right: 1px solid #238787;
      }

      &.no-border {
        border: none;
      }
    }

    tr .header-cell {
      padding: 8px;
      border-top: 1px solid #238787;

      &:last-child {
        border-right: 1px solid #238787;
      }

      &:first-child {
        border-left: 1px solid #238787;
      }

      &.no-border {
        border: none;
      }
    }

  }
}

.arc-stats-tbl {
  tbody tr {
    .cell {
      &:not(:first-child) {
        border-left: none;
      }

      &:nth-child(2) {
        color: black;
        background-color: #DFF;
      }
    }

    &:last-child {
      .cell {
        border-bottom: 1px solid #238787;
      }
    }
    &:nth-child(odd) {
      .cell {
        background-color: #DFF;
      }
    }
  }
}

.auditor-stats-tbl {

  tbody tr {
    .cell {

      &:nth-child(2),
      &:nth-child(7) {
        color: black;

      }

    }

    .cell {
      &:not(:first-child) {
        border-left: none;
      }
    }

    &:last-child {
      .cell {
        border-bottom: 1px solid #238787;
      }
    }

    &:nth-child(odd) {
      .cell {
        background-color: #DFF;
      }
    }
  }
}

.investigator-stats-tbl {

  tbody tr {
    .cell {

      &:not(:first-child) {
        border-left: none;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        background-color: #DFF;
        color: black;
      }
    }

    &:last-child {
      .cell {
        border-bottom: 1px solid #238787;
      }
    }

    &:nth-child(odd) {
      .cell {
        background-color: #DFF;
      }
    }
  }
}

.edu-dept-stats-tbl {

  tbody tr {
    .cell {
      &:not(:first-child) {
        border-left: none;
      }

      &:nth-child(2),
      &:nth-child(3) {
        color: black;
        background-color: #DFF;
      }

      &:nth-child(2) {
        background-color: #DFF;
      }
    }

    &:last-child {
      .cell {
        border-bottom: 1px solid #238787;
      }
    }

    &:nth-child(odd) {
      .cell {
        background-color: #DFF;
      }
    }

  }
}

.ellipses-text {
  width: 30ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipses-text:hover {
  white-space: wrap;
  overflow: visible;
  text-overflow: clip;
}

.app-color-header {
  color: black;
}
.fixed-row-height{
  line-height: 15px;
}
.reduce-row-height{
  line-height: 20px; // can reduce accordingly
}
.row-height{
  line-height: 19px;
}
.shadow-box{
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.5);
}
.auditor-table-container {
  width: 100%;
  overflow-x: auto; /* Add horizontal scroll for smaller screens */
}

.auditor-stats-tbl {
  width: 100%;
}

@media (max-width: 768px) {
  .auditor-stats-tbl td,
  .auditor-stats-tbl th {
    white-space: nowrap; /* Prevent text wrapping */
  }
}