.export-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.export-top-left-logo {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 0px;
}

.export-top-right-logo {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 0px;
}

.export-bottom-left-logo {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding-bottom: 35px;
    margin-left: -5px;
}

.export-bottom-right-logo {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding-bottom: 35px;

}
.export-bottom-page-no{
    position: absolute;
    bottom: 0px;
    left: 45%;
    padding-bottom: 35px;
}
.export-center-content {
    flex-grow:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    padding-top: 100px;
}